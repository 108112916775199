import { ErrorDisplay } from "@lifesg/react-design-system/error-display";
import translations from "app/assets/files/key-value-translations/error404.json";
import Segment from "app/components/page/Segment";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import Head from "next/head";
import { Grid } from "semantic-ui-react";
import "./ErrorPages.scss";

const Error404 = (): JSX.Element => {
	const currentUrl: string[] = window.location.pathname.split("/");
	const isVault = currentUrl[1] === "vault" ? true : false;
	const [, selectedLanguage] = useGlobalLanguageState();

	const getTranslatedCopy = (key: string): string => {
		const _translations = translations[selectedLanguage];
		return _translations[key as keyof typeof _translations].data;
	};

	return (
		<Segment>
			<Head>
				<title>
					{getTranslatedCopy("PageTitle")} - {isVault ? "Legacy vault" : "My Legacy"}
				</title>
			</Head>
			<Grid>
				<Grid.Row>
					<Grid.Column className="no-margin no-padding" mobile={12} tablet={12} computer={12}>
						<div className="page__unexpected-error">
							<div className="error-container">
								<ErrorDisplay
									type="404"
									title={getTranslatedCopy("ThisPageCannotBeFound")}
									description={getTranslatedCopy("ThisMightBeDueTo")}
								/>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Error404;
